@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #ffffff;
  --secondary-color: #be845a;
}

body {
  margin: 0;
  background-color: #272727;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  max-width: 100%;
  overflow-x: hidden !important;
}

h1 {
  font-size: 64pt;
  margin: 0;
  color: #ffffff;
  padding: 0;
}

h2 {
  font-weight: bold;
  font-size: 32pt;
  margin: 0;
  color: #ffffff;
}

.logo-clr {
  cursor: pointer;
  width: 50px; /* Set the width and height to be the same for a perfect circle */
  height: 50px;
  color: #ffffff;
}

h3 {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 24 pt;
  font-weight: Medium;
  line-height: 1;
  color: #d6d6d6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #8f9093;
  font-size: larger;
}

ul {
  list-style-type: none;
}

p {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14pt;
  font-weight: medium;
  letter-spacing: 0;
  color: #d6d6d6;
  opacity: 1;
  margin: 0;
}

/*flex containers*/
.flex-wrapper {
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 1rem 1rem 1rem 1rem;
}

.flex-grid {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.flex-grid:nth-child(odd) div {
  padding: 1rem 0;
}
.flex-grid:nth-child(even) div {
  padding: 1rem 0;
}

/*custom styling*/

.about-image {
  width: 400px;
  height: 400px;
  margin-bottom: auto;
}

.grey-p {
  color: #8f9093;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #151414;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.line {
  background-color: var(--primary-color);
}

/* hoverover tooltip */
.hover-over {
  font-weight: bold;
  position: relative;
  color: #be845a;
  transition: color 0.3s;
}

.hover-over:hover {
  color: #ffff;
}

.hover-over span {
  opacity: 0;
  visibility: hidden;
  background-color: #333; /* Tooltip background color */
  color: #fff; /* Tooltip text color */
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  position: absolute;
  bottom: -24px; /* Adjust the spacing from the anchor */
  left: 50%;
  transform: translateX(-50%);
  transition:
    opacity 0.3s,
    visibility 0.3s;
}

.hover-over:hover span {
  opacity: 1;
  visibility: visible;
}

.scrollable-content {
  max-height: 100%;
  overflow-y: auto;
}

/*Navbar*/

.cv-logo {
  width: auto;
  height: auto;
  font:
    normal 300 24px/29px "Helvetica Neue",
    sans-serif;
  letter-spacing: 0.96px;
  opacity: 1;
  margin-top: 5px;
  margin-right: auto;
  margin-left: -0.5rem;
  color: #be845a;
}

.nav-header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  background-color: #272727;
}

.nav-header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.nav-header .nav-menu a {
  color: #ffffff;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  opacity: 1;
  color: #ffffff;
  position: relative;
  padding: 5px;
  text-decoration: none;
}

.nav-item a:hover {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-color);
}

/* bullet point for nav items (removed for now) */

/* .nav-item::before {
  content: "\2022";
  color: #be845a; 
  font-size: 16px;
  margin-right: 8px; 
} */

.nav-header .nav-menu {
  display: flex;
}

.nav-header .nav-item {
  padding: 1rem;
  font-weight: 500;
  color: #ffffff;
}

/*nav line*/
.nav-header .nav-item a:hover {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--primary-color);
}

.nav-item .active {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--secondary-color);
}

@media screen and (max-width: 2600px) and (min-width: 2450px) {
  .hero-large {
    padding: 18rem 16rem 0 16rem;
  }

  .about-large {
    padding: 20rem 28rem 0 16rem;
  }

  .skill-large {
    padding: 16rem 26rem 0 36rem;
  }

  .footer-large {
    padding: 20rem 28rem 0 16rem;
  }
}

@media screen and (max-width: 1040px) {
  .nav-header {
    max-width: 100%;
    background-color: #272727;
    display: hidden;
  }

  .nav-header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
    color: #be845a;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: #272727;
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .nav-header .navbar img {
    width: 150px;
  }
}

/* Hero component */

.hero-img {
  width: 200px;
  height: 300px;
  margin: auto;
  position: relative;
}

.hero {
  margin-top: 15%;
  margin-left: 10%;
}

.hero h1 {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 34pt;
  line-height: 42pt;
  text-align: left;
  margin: auto;
  color: #ffffff;
  padding: 0;
}

.custom-button {
  /* Sizing and Positioning */
  margin-top: 20px;
  margin-left: -10px;
  position: relative;
  display: inline-block;

  /* Typography */
  font-family: "Helvetica Neue", sans-serif;
  font-size: 24pt;
  font-weight: 24;
  color: #ffffff;

  /* Background */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 48px;

  /* Spacing and Padding */
  padding: 15px 32px;

  /* Cursor */
  cursor: pointer;
}

/*  skills */

.skillGrid {
  margin-top: auto;
}

.skillBox {
  /* display: inline-block; */
  border: 0.6px solid #be845a;
  padding: 8px 20px;
  /* margin: 4px; */
  border-radius: 8px;
  opacity: 1;
}

.skill-image {
  height: 300px;
  width: 300px;
  justify-items: auto;
}

/*Content*/

.content {
  margin: auto;
  padding: 16rem 16rem 0 16rem;
  background-color: #2e2e2e;
}

.content-1 {
  margin: auto;
  padding: 16rem 16rem 0 16rem;
  background-color: #272727;
}

/* Projects */
.project-img-card {
  width: 600px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.project-vid-card {
  width: 600px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.project-vid-card video {
  width: 600px;
  height: auto;
}

.project-img-card img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 15px 15px 0 0;
}

.project {
  overflow-y: scroll;
}

.component-buttons {
  display: flex;
  flex-flow: column;
}

.component-buttons button {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  margin: 10px;
  border-radius: 4px;
  padding: 5px 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.component-buttons button.active {
  background-color: #ffff; /* Change the color for active button */
}

.component-buttons button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/*Footer*/

.contact-button {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  width: 80px; /* Set the width and height to be the same for a perfect circle */
  height: 80px;
  border-radius: 50%; /* Use border-radius: 50% to create a circle */
  color: #fff;
  transition: background-color 0.3s ease;

  /* Optional: Hover styles */
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

@media (min-width: 980px) {
  .flex-wrapper {
    padding-left: 6%;
    padding-right: 6%;
  }

  .flex-grid {
    margin-top: 2rem;
  }
  .flex-grid:first-child {
    margin-top: 0;
  }

  .d-col-1 {
    width: 7.05%;
  }
  .d-col-2 {
    width: 15.5%;
  }
  .d-col-3,
  .d-col-thirds {
    width: 23.95%;
  }
  .d-col-4,
  .d-col-quarters {
    width: 32.4%;
  }
  .d-col-5 {
    width: 40.85%;
  }
  .d-col-6,
  .d-col-halfs {
    width: 49.3%;
  }
  .d-col-7 {
    width: 57.75%;
  }
  .d-col-8 {
    width: 66.2%;
  }
  .d-col-9 {
    width: 74.65%;
  }
  .d-col-10 {
    width: 83.1%;
  }
  .d-col-11 {
    width: 91.55%;
  }
  .d-col-12,
  .d-col-full {
    width: 100%;
  }

  .content {
    margin: auto;
    padding: 4rem 4rem 0 4rem;
    background-color: #2e2e2e;
  }

  .content-1 {
    margin: auto;
    padding: 4rem 8rem 0 4rem;
    background-color: #272727;
  }
}

@media (max-width: 979px) and (min-width: 0px) {
  .flex-wrapper {
    padding-left: 6%;
    padding-right: 6%;
  }

  .flex-grid {
    margin-top: 2rem;
    margin: auto;
  }
  .flex-grid:first-child {
    margin-top: 0;
  }

  .d-col-1 {
    width: 7.05%;
  }
  .d-col-2 {
    width: 15.5%;
  }
  .d-col-3,
  .d-col-thirds {
    width: 23.95%;
  }
  .d-col-4,
  .d-col-quarters {
    width: 32.4%;
  }
  .d-col-5 {
    width: 40.85%;
  }
  .d-col-6,
  .d-col-halfs {
    width: 49.3%;
  }
  .d-col-7 {
    width: 57.75%;
  }
  .d-col-8 {
    width: 66.2%;
  }
  .d-col-9 {
    width: 74.65%;
  }
  .d-col-10 {
    width: 83.1%;
  }
  .d-col-11 {
    width: 91.55%;
  }
  .d-col-12,
  .d-col-full {
    width: 100%;
  }

  .custom-button {
    margin-top: 10%;
    font-size: 10pt;
    font-weight: 10pt;
    padding: 10px 10px;
  }

  .cv-logo {
    width: auto;
    height: auto;
    margin-left: 0.1rem;
  }

  .about-image {
    width: 200px;
    height: 200px;
  }

  .hero h1 {
    font-size: 18pt;
    line-height: 18pt;
  }

  .hero-img {
    width: 100px;
    height: 200px;
  }

  p {
    font-size: 12px;
  }

  .skill-image {
    visibility: hidden;
  }

  .content {
    margin: auto;
    padding: 4rem 1rem 0 1rem;
    background-color: #2e2e2e;
  }

  .content-1 {
    margin: auto;
    padding: 1rem 1rem 0 1rem;
    background-color: #272727;
  }

  .project-img-card {
    width: 300px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .project-vid-card {
    width: 350px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .project-vid-card video {
    width: 350px;
    height: auto;
  }
}
